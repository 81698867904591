import Home from './home'
import Information from './information'

const sections = [
	new Home,
	new Information

]

export default sections
