import select from 'dom-select'
import bind from 'es6-class-bind-all'
import event from 'dom-event'
import InView from 'inview'
import $ from 'jquery'

class Nav {
	constructor() {
		bind(this)

		this.cache()
		this.init()
	}
	cache() {

		
	}

	init() {
		

    }

}

export default Nav