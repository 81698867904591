import Default from './default'
import configQ from '../utils/func/configQ'
import config from '../config'
import InView from 'inview'
import chargeImage from '../utils/chargeImage'


class Home extends Default {
	constructor() {
		super('home')
	}
	mounted() {
        configQ(window.innerWidth)
        this.cache()
        this.init()

    }

    cache() {

        this.thumbs = [...document.querySelectorAll('.image_wrapper')]

        this.wait = false
        this.set

    }

    init() {
          
        this.initMonitor()
        this.addEvents('on')

    }

    addEvents(type) {


    }

    initMonitor() {

        this.thumbs.forEach((thumb, index) => {

            InView(thumb, function(isInView, data) {

                if (isInView) {
                    

                        const image = thumb.querySelector('img')
                        thumb.classList.add('inview')
                        if(image && image?.dataset.src) {
                            console.log('cha1')
                            chargeImage(image)
                        }
                        this.destroy()
                } else {
                    if (data.windowScrollTop - (data.elementOffsetTop - data.inViewHeight) > data.inViewHeight) {
                        
                        if(data.elementOffsetTop + data.elementOffsetTopInViewHeight > window.innerHeight * -2) {
                            thumb.classList.add('inview')
                            const image = thumb.querySelector('img')
                            if(image && image?.dataset.src) {
                                chargeImage(image)
                            }
                            this.destroy()
                        }

                      index == 1 && console.log('not in view (scroll up)', data)
                    } else {
                        // index == 1 && console.log('not in view (scroll down)', data)
                        if(data.elementOffsetTopInViewHeight < window.innerHeight * 2) {
                            thumb.classList.add('inview')
                            const image = thumb.querySelector('img')
                            if(image && image?.dataset.src) {
                                chargeImage(image)
                            }
                            this.destroy()
                        }
                    }
                }
            })

        })

    }

	beforeDestroy() {

        this.addEvents('off')

    }

	resize(w) {

        this.mid = (window.innerWidth - 40) / 2
		const oldConfig = config.isMobile        
        configQ(w)
        if (oldConfig !== config.isMobile) {
            this.addEvents('off')
            this.mounted()
        }

	}
}

export default Home